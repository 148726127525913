/* color declarations */
:root {
   --hover-bg-color: rgb(129, 218, 166);
   --primary: rgb(28, 30, 33);
   --bg-color: rgba(0, 0, 0, 0.7);
   --secondary: rgb(36, 40, 45);
   --tertiary: rgb(46, 49, 54);
   --text-color: rgb(255, 255, 255);
   --secondary-text-color: rgb(179, 179, 179);
   --accent-color: rgb(26, 188, 156);
   --accent-color-muted: rgb(26, 142, 129);
}

html {
   background-color: var(--primary) !important;
   height: 100%;
}

body {
   font-family: 'Roboto Mono', monospace !important;
   background-color: var(--primary) !important;
   color: var(--text-color);
   display: flex;
   flex-direction: column;
   height: 100%;
   /* background-blend-mode:hard-light; */
   background-blend-mode: hard-light;
}

#root {
   flex: 1;
}

/* animations */

/* terminal cursor blink */
@keyframes cursor-blink {
   50% {
      background-color: white;
   }
}

.terminal-cursor {
   display: inline-block;
   width: 0.5em;
   height: 1em;
   background-color: transparent;
   position: absolute;
   top: 40%;
   right: 0%;
   transform: translateY(-40%);
}

/* animation triggers once the .animate class is added to element with this class
   this lets us trigger the animation when we want, for instance when typing text finishes
*/
.terminal-cursor.animate {
   animation: cursor-blink 1s infinite steps(1);
}

/* text typing animation */
@keyframes text-typing {
   from {
      width: 0;
   }

   to {
      width: 100%;
   }
}

@keyframes text-typing-wrap {
   from {
      max-width: 0;
   }

   to {
      max-width: 100%;
   }
}

.skip-typing-animation {
   animation-play-state: paused !important;
   width: 100% !important;
}

/* the steps have to match the word count, otherwise letters will show cut off - so each instance needs it's own class with
   the correct step count */
.title-typing-text {
   display: inline-block;
   overflow: hidden;
   white-space: nowrap;
   animation: text-typing 1.3s steps(18, end) forwards;
   animation-fill-mode: forwards;
}

/* bg color mask */
.mask {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   mix-blend-mode: multiply;
   /* Set the blend mode */
}

.slider-wrapper {
   position: absolute;
   top: 80%;
   left: 0;
   width: 100%;
   display: flex;
   justify-content: center;
}

.slider-wrapper>* {
   position: relative;
}

.baseFrequencyXContainer {
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
   z-index: 100;
   height: 100px;
   width: 300px;
}

.baseFrequencyYContainer {
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
   z-index: 100;
   height: 100px;
   width: 300px;
}

.filterFrequencyXContainer {
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
   z-index: 100;
   height: 100px;
   width: 300px;
}

.filterFrequencyYContainer {
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
   z-index: 100;
   height: 100px;
   width: 300px;
}

.numberOfOctavesContainer {
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
   z-index: 100;
   height: 100px;
   width: 300px;
}

.animatedTransitionToggleContainer {
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
   z-index: 100;
   height: 100px;
   width: 300px;
}

label {
   color: var(--text-color);
   opacity: 0.8;
}

.sliderInput {
   display: flex;
   background-color: transparent !important;
   border: 0px solid black !important;
   text-align: center !important;
   margin-bottom: 10px !important;
}

.p-slider-range {
   background-color: var(--accent-color) !important;
}

.p-slider .p-slider-handle {
   background-color: var(--accent-color) !important;
   border: 2px solid var(--primary) !important;
   transition: 0.2s ease-in-out;
}

.p-button.p-togglebutton {
   display: flex !important;
   background-color: transparent !important;
   background-image: linear-gradient(to right, var(--accent-color) 100%, var(--accent-color) 100%) !important;
   background-repeat: no-repeat !important;
   border: 1px solid var(--accent-color) !important;
   background-size: 0% 100% !important;
   transition: background-size 0.3s ease !important;
   height: 45px !important;
}

.p-togglebutton:hover {
   /* background-color: var(--accent-color-muted) !important; */
   background-size: 100% 100% !important;
}

.p-togglebutton.p-button.p-highlight {
   background-color: var(--accent-color) !important;
   color: var(--text-color) !important;
   border: none !important;
}

.emissive-shadow {
   background-color: rgba(0, 0, 0, 0.7);
   box-shadow: 0 0 10px rgba(26, 188, 156, 0.1),
      0 0 20px rgba(26, 188, 156, 0.1),
      0 0 30px rgba(26, 188, 156, 0.1);
   transition: 0.1s ease-in-out;
}

.emissive-shadow:hover {
   background-color: rgba(0, 0, 0, 1);
   box-shadow: 0 0 20px rgba(26, 188, 156, 0.1),
      0 0 30px rgba(26, 188, 156, 0.1),
      0 0 40px rgba(26, 188, 156, 0.1);
}

/* Component Transition Styles */
.page-transition-enter {
   opacity: 0;
}

.page-transition-enter-active {
   opacity: 1;
   transition: opacity 2000ms;
}

.page-transition-exit {
   opacity: 1;
}

.page-transition-exit-active {
   opacity: 0;
   transition: opacity 2000ms;
}