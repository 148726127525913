.wave-bg {
   position: absolute;
   top: 50%;
   left: 0;
   width: 100%;
   height: 100%; /* increasing this value shrinks the SVG wave - something weird going on with the scalars, but this creates a better visual */
   z-index: -2;
   pointer-events: none;
   transform: translateY(-50%);
}

/* individual wave class */
.wave {
   position: absolute;
   bottom: 0%;
   left: 0;
   width: 200%;
   height: auto;
   animation: wave 60s linear infinite;
   box-shadow: 0 -5px 5px rgba(0, 0, 0, .2);
}

.wave:nth-child(1) {
   animation-delay: -30s;
}

.wave:nth-child(2) {
   animation-delay: 0s;
}
/* 
.wave:nth-child(3) {
   animation-delay: 13.32s;
} */

@keyframes wave {
   0% {
      transform: translateX(-100%);
   }

   100% {
      transform: translateX(100%);
   }
}

/* Styles for the SVG */
.wave svg {
   display: block;
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   height: auto;
}