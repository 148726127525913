.fullHeight {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 35rem;
}

.whoAreYou {
   color: var(--text-color);
   font-size: 2rem;
   margin-bottom: 2rem;
}

.getStartedContainer {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.cardContainer {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
}

.getStarted-cardContent {
   z-index: 2;
   width: 300px;
   height: 300px;
   font-size: 1.5rem;
   margin: 0 2rem;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: center;
   position: relative;
   overflow: hidden;
   transition: 0.01s ease-in-out;
}

.getStarted-card {
   width: 300px;
   height: 300px;
   color: var(--accent-color);
   font-size: 1.5rem;
   margin: 0 2rem;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: center;
   position: relative;
   z-index: 1;
   overflow: hidden;
   transition: 0.01s ease-in-out;
}

.getStarted-card::after {
   content: "";
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   background-color: var(--accent-color);
   z-index: -1;
   transform: scale(0);
   border-radius: 50%;
   transition: transform 0.3s, border-radius 0.5s;
}

.getStarted-card.active::after {
   transform: scale(1);
   border-radius: 0;
}

.getStarted-card.active {
   /* filter: brightness(150%); */
   border-radius: 0px;
   color: var(--text-color);
   cursor: pointer;
}

.getStarted-card:hover {
   cursor: pointer;
}

.getStarted-buttonRow {
   display: flex;
   width: 100%;
   align-items: flex-end;
   justify-content: flex-end;
   margin-top: 3rem;
   margin-right: 4rem;
}

.getStarted-buttonRow a {
   border: 1px solid var(--accent-color);
   padding: 10px;
   border-radius: 5px;
   transition: 0.2s ease-in-out;
}