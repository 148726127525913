.homeContainer {
   display: flex;
   flex-direction: column;
   height: calc(30vh);
   margin-top: 100px;
}

.missonStatement-container {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-grow: 1;
   position: relative;
}

.missionStatement-text {
   color: var(--text-color);
   font-size: 1.5rem;
   margin: 0 15rem;
   padding: 1rem 1rem 1rem 4rem;
   border-left: 30px solid var(--accent-color);
   /* animation: grow-border-left 0.4s ease-in-out forwards; */
   /* TODO: this animation is lagging on page transition - determine why */
}

.missionStatement-buttonRow {
   display: flex;
   align-items: end;
   justify-content: end;
   margin: 0rem 15rem;
   margin-top: 1rem;
}

.missionStatement-buttonRow a {
   border: 1px solid var(--accent-color);
   padding: 10px;
   border-radius: 5px;
   transition: 0.2s ease-in-out;
}

.missionStatement-buttonRow a:hover {
   border: 1px solid var(--accent-color);
   padding: 10px;
   border-radius: 5px;
   box-shadow: 3px 5px 8px black;
}

/* medium screen sizes */
@media (max-width: 1400px) {

   .homeContainer {
      display: flex;
      flex-direction: column;
      height: calc(30vh);
   }

   .missionStatement-text {
      color: var(--text-color);
      font-size: 1.5rem;
      margin: 0 7rem;
      padding: 1rem 1rem 1rem 4rem;
      border-left: 30px solid var(--accent-color);
      /* animation: grow-border-left 0.4s ease-in-out forwards; */
      /* same todo as above */
   }

   .missionStatement-buttonRow {
      display: flex;
      align-items: end;
      justify-content: end;
      margin: 0rem 7rem;
      margin-top: 1rem;
   }
}

/* small screen sizes */
@media (max-width: 1200px) {

   .homeContainer {
      display: flex;
      flex-direction: column;
      height: calc(10vh);
   }

   .missonStatement-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      position: relative;
   }

   .missionStatement-text {
      color: var(--text-color);
      font-size: 1.5rem;
      margin: 0 2rem;
      padding: 1rem 1rem 1rem 4rem;
      border-left: 30px solid var(--accent-color);
      /* animation: grow-border-left 0.4s ease-in-out forwards; */
      /* same todo as above */
   }

   .missionStatement-buttonRow {
      display: flex;
      align-items: end;
      justify-content: end;
      margin: 0rem 2rem;
      margin-top: 1rem;
   }
}

/* small screen sizes */
@media (max-width: 1000px) {

   .homeContainer {
      display: flex;
      flex-direction: column;
      height: calc(10vh);
      margin-top: 40px;
   }

   .missonStatement-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      position: relative;
   }

   .missionStatement-text {
      color: var(--text-color);
      font-size: 1.5rem;
      margin: 0 2rem;
      padding: 3rem 2rem 3rem 3rem;
      border-left: 15px solid var(--accent-color);
      /* animation: grow-border-left 0.4s ease-in-out forwards; */
      /* same todo as above */
   }

   .missionStatement-buttonRow {
      display: flex;
      align-items: end;
      justify-content: end;
      margin: 0rem 2rem;
      margin-top: 1rem;
   }
}

@keyframes grow-border-left {
   0% {
      border-left-width: 0px;
   }

   100% {
      border-left-width: 30px;
   }
}

/* css below works for a close representation of type writer animation with line wraps, but it is not perfect */

/* .missonStatement-container {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-grow: 1;
   height: calc(50vh);
   position: relative;
   width: 100%;
   max-width: 600px;
   margin: 0 auto;
   overflow-wrap: break-word;
   word-wrap: break-word;
   hyphens: auto;
}

.missionStatement-text {
   font-family: monospace;
   font-size: 1rem;
   white-space: pre-wrap;
   border-right: 2px solid;
   overflow: hidden;
   max-height: 6em;
   color: var(--text-color);
   animation: typing 1.2s steps(108, end), blinking-cursor 0.75s step-end infinite alternate;
}

@keyframes typing {
   0% {
      width: 0;
      max-height: 0;
   }

   50% {
      max-height: 3em;
   }

   100% {
      width: 100%;
      max-height: 6em;
   }
}

@keyframes blinking-cursor {
   from {
      border-color: transparent;
   }

   to {
      border-color: inherit;
   }
} */