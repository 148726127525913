nav {
   position: relative;
   display: flex;
   justify-content: space-between;
   overflow: hidden;
   align-items: center;
   height: 100px;
   padding: 0 2rem;
   z-index: 1;
   background-color: rgba(var(--secondary), 0.9);
   box-shadow: 0px 3px 5px black;
}

ul {
   display: flex;
   list-style: none;
   padding: 0;
   margin: 0;
   align-items: center;
   line-height: inherit;
   margin-left: 2rem;
}

li {
   margin-right: 3rem;
   display: flex;
   vertical-align: middle;
   margin-top: 1.2rem;
}

a {
   text-decoration: none !important;
   color: var(--secondary-text-color) !important;
   padding: 0.2rem;
   background-image: linear-gradient(to right, var(--accent-color) 100%, var(--accent-color) 100%);
   background-repeat: no-repeat;
   background-size: 0% 100%;
   transition: background-size 0.3s ease;
   /* transition: background-color 0.2s ease-out, padding 0.2s ease-out, color 0.2s ease-out; */
}

a:hover {
   /* background-color: var(--accent-color) !important; */
   color: var(--text-color) !important;
   background-size: 100% 100%;
}

.siteTitle {
   font-size: 2rem;
   color: var(--text-color);
   position: relative;
   display: inline-block;
   overflow: hidden;
}

.titleContent {
   padding: 20px;
   color: var(--text-color);
   z-index: 1;
   position: relative;
}

/* Site title hover effect */
/* Base styles for the div */
/* .siteTitle {
   position: relative;
   display: inline-block;
   padding: 10px;
   background-color: #1c1e21;
   color: #ffffff;
   overflow: hidden;
   background-image: linear-gradient(to right, var(--accent-color) 100%, var(--accent-color) 100%);
   background-repeat: no-repeat;
   background-size: 0% 100%;
   transition: background-size 0.3s ease;
}

/* Animate the background color fill on hover */
/* .siteTitle:hover {
   background-size: 100% 100%;
} */